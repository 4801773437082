.dropdown-menu {
  border: none;
  background-color: transparent;
  box-shadow: none;
  li {
    background-image: url(../images/white.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: -3px;
    a {
      &, &:hover, &:focus {
        padding: 8px 20px;
        background: none;
        font-weight: bold;
      }
    }
  }
}