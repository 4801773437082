.region-sidebar-first {
  .views-exposed-form .views-exposed-widget {
    float: none;
    clear: both;
  }
}

body {
  font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-size: 16px;
  background-color: $c-rose-bg;
  background-size: 100% auto;
  background-repeat: repeat-y;
  background-image: url(../images/background-xs.jpg);
  @include breakpoint($screen-xs) {
    background-image: url(../images/background-sm.jpg);
  }
  @include breakpoint($screen-sm) {
    background-image: url(../images/background-md.jpg);
  }
  @include breakpoint($screen-md) {
    background-image: url(../images/background-lg.jpg);
  }
  @include breakpoint($screen-lg) {
    background-image: url(../images/background-xl.jpg);
  }
}

a {
  color: darken($c-rose, 50%);
  &:hover, &:focus {
    color: darken($c-rose, 55%);
    text-decoration: none;
  }
}

.navbar-default {
  background-color: transparent;
  border-color: transparent;
  @include breakpoint($screen-sm) {
    background-image: url(../images/white.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 25px 7% 25px 6%;
  }

  .navbar-toggle {
    display: block;
    border-color: $c-white;
    float: none;
    width: 60px;
    margin: 0 auto;
    &:hover, &:focus {
      background-color: transparent;
    }
    .icon-bar {
      width: 100%;
      background-color: $c-white;
      + .icon-bar {
        margin-top: 6px;
      }
    }
    @include breakpoint($screen-sm) {
      display: none;
    }
  }

  .navbar-nav {
    > li {
      > a {
        color: $c-black;
        font-weight: bold;
        font-size: 18px;
      }
      a {
        text-align: center;
        display: block;
        @include breakpoint($screen-sm) {
          display: inline-block;
        }
      }
      @include breakpoint($screen-sm) {
        &:after {
          content: "";
          height: 1.5em;
          width: 2px;
          background-color: $c-rose;
          display: inline-block;
          vertical-align: middle;
        }
        &:last-child:after {
          content: none;
        }
      }
    }
    > .open,
    > .active {
      > a {
        &, &:hover, &:focus {
          background-color: transparent;
          color: $c-crail;
        }
      }
    }

  }
}

.page-header {
  color: $c-white;
  border-bottom-color: $c-rose;
}
