.node-page {
  .field-body {
    .newline {
      clear: both;
    }
    ul, ol {
      overflow: hidden;
    }
    figure {
      margin: 10px 0;
      text-align: center;
      figcaption {
        margin-top: 1em;
        font-size: 95%;
      }
      @include breakpoint(max-width $screen-xs-max) {
        float: none !important;
        width: 100%;

      }

      &[style*="float:left"], &[style*="float: left"] {
        margin-right: 0;
        @include breakpoint($screen-sm) {
          margin-right: 20px;
        }
      }
      &[style*="float:right"], &[style*="float: right"] {
        margin-left: 0;
        @include breakpoint($screen-sm) {
          margin-left: 20px;
        }
      }

    }

    img {
      border-radius: 20px;
      box-shadow: 3px 3px 10px $c-lightgray;
      max-width: 100%;
      height: auto;
    }

    h2 {
      margin: 1em 0;
      &.rtecenter {
        clear: both;
      }
      @include breakpoint(max-width $screen-xs-max) {
        text-align: center;
      }
    }

    s {
      @extend %stroke;
      text-decoration: none;
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      display: block;
      line-height: 1.25;
      padding-left: 0.75em;
      margin-left: 0.25em;
      padding-right: 1.0em;

      @include breakpoint($screen-xs) {
        padding-left: 0.75em;
        margin-left: 1.25em;
        padding-right: 2em;
      }
      @include breakpoint($screen-sm) {
        display: inline;
        margin-left: -0.75em;
        margin-right: 2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }


    .box {
      text-align: center;
      p {
        display: inline-block;
        background-color: transparent;
        background-image: url(../images/about/Rose-bloc-transparent.png);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        padding: 30px 20px 80px;
        margin-bottom: 0;
        @include breakpoint($screen-sm) {
          padding: 50px 50px 80px;
        }
      }
    }

    .cols-2 {
      display: flex;
      .col-right {
        flex-shrink: 1;
      }
    }
    .stroke {
      @extend %stroke;
      margin-left: -1em;
      padding-left: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-right: 2em;
    }
    .jumbotron {
      background-color: transparent;
      background-image: url(../images/about/Rose-bloc-transparent.png);
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      text-align: center;
      padding: 50px 50px 80px;
      margin-bottom: 0;
      display: block;
    }

    .center {
      text-align: center;
    }

    .img-small {
      box-shadow: 10px 10px 10px $c-lightgray;
      @include breakpoint($screen-sm) {
        max-width: 200px;
        height: auto;
      }
      &.landscape {
        @include breakpoint($screen-sm) {
          max-height: 200px;
          max-width: none;
          width: auto;
        }
      }
      &.pull-left {
        margin: 10px 25px 20px 0;
      }
      &.pull-right {
        margin: 10px 0 20px 25px;
      }
    }
    .img-rounded {
      border-radius: 20px;
    }
  }
}
