#site-logo {
  display: block;
  width: 100%;
  @include breakpoint($screen-xs) {
    width: 300px;
  }
  height: 150px;
  margin: 0 auto;
  background-image: url(../images/box.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    content: "";
    display: block;
    background-image: url(../images/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  > span {
    @include sr-only;
  }
  + .slogan {
    text-align: right;
    margin: 0 auto;
    width: 100%;
    @include breakpoint($screen-md) {
      width: 90%;
    }
    @include breakpoint($screen-md) {
      width: 80%;
    }
    @include breakpoint($screen-md) {
      width: 70%;
    }

  }

  .front & {
    height: 0;
    width: 100%;
    padding-bottom: 50%;
    @include breakpoint($screen-md) {
      width: 80%;
      padding-bottom: 0.8 * 50%;
    }
    @include breakpoint($screen-md) {
      width: 70%;
      padding-bottom: 0.7 * 50%;
    }
    @include breakpoint($screen-md) {
      width: 60%;
      padding-bottom: 0.6 * 50%;
    }
  }
}
