#page-footer {
  background-color: $c-heavy-metal;
  color: $c-white;
  border-top: none;
  background-image: url(../images/hibiscus.jpg);
  background-position: top right;
  background-size: auto 150px;
  @include breakpoint($screen-sm) {
    background-size: auto 100%;
  }
  background-repeat: no-repeat;
  a, h2, h3, h4 {
    color: $c-carnation;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0 0 6px #000;
  }
  .region-footer {
    text-align: center;
    @include breakpoint($screen-sm) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
  #block-bean-kontakty {
    margin-bottom: 40px;
    @include breakpoint($screen-sm) {
      text-align: left;
      margin-bottom: 0;
    }
  }
  #block-bean-sleduy-za-svetoy {
    margin-bottom: 40px;
    @include breakpoint($screen-sm) {
      margin-bottom: 0;
    }
    .social-links {
      list-style: none;
      padding-left: 0;
      @include breakpoint($screen-sm) {
        display: flex;
        justify-content: space-between;
      }
      li {
        display: inline-block;
        a {
          display: block;
          width: 40px;
          height: 40px;
          border: 1px solid $c-white;
          border-radius: 10px;
          transition: border-color 300ms, color 300ms;
          color: $c-white;
          text-align: center;
          line-height: 40px;
          font-size: 20px;
          margin: 0 10px;
          &:before {
            @include fa-icon;
            color: inherit;
            content: $fa-var-vk;
          }

          &.ig:before {
            content: $fa-var-instagram;
          }
          &.fb:before {
            content: $fa-var-facebook;
          }
          > span {
            @include sr-only;
          }
        }
        &:hover, &:focus {
          a {
            border-color: $c-carnation;
            color: $c-carnation;
          }
        }
      }
    }
  }
  #block-bean-avtorskie-prava {
    align-self: flex-end;
    .content {
      margin-top: 1em;
    }
  }
}

