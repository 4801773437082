$icon-font: 'Glyphicons Halflings';
$glyphicon-asterisk: "\002a";
$glyphicon-plus: "\002b";
$glyphicon-euro: "\20ac";
$glyphicon-minus: "\2212";
$glyphicon-cloud: "\2601";
$glyphicon-envelope: "\2709";
$glyphicon-pencil: "\270f";
$glyphicon-glass: "\e001";
$glyphicon-music: "\e002";
$glyphicon-search: "\e003";
$glyphicon-heart: "\e005";
$glyphicon-star: "\e006";
$glyphicon-star-empty: "\e007";
$glyphicon-user: "\e008";
$glyphicon-film: "\e009";
$glyphicon-th-large: "\e010";
$glyphicon-th: "\e011";
$glyphicon-th-list: "\e012";
$glyphicon-ok: "\e013";
$glyphicon-remove: "\e014";
$glyphicon-zoom-in: "\e015";
$glyphicon-zoom-out: "\e016";
$glyphicon-off: "\e017";
$glyphicon-signal: "\e018";
$glyphicon-cog: "\e019";
$glyphicon-trash: "\e020";
$glyphicon-home: "\e021";
$glyphicon-file: "\e022";
$glyphicon-time: "\e023";
$glyphicon-road: "\e024";
$glyphicon-download-alt: "\e025";
$glyphicon-download: "\e026";
$glyphicon-upload: "\e027";
$glyphicon-inbox: "\e028";
$glyphicon-play-circle: "\e029";
$glyphicon-repeat: "\e030";
$glyphicon-refresh: "\e031";
$glyphicon-list-alt: "\e032";
$glyphicon-lock: "\e033";
$glyphicon-flag: "\e034";
$glyphicon-headphones: "\e035";
$glyphicon-volume-off: "\e036";
$glyphicon-volume-down: "\e037";
$glyphicon-volume-up: "\e038";
$glyphicon-qrcode: "\e039";
$glyphicon-barcode: "\e040";
$glyphicon-tag: "\e041";
$glyphicon-tags: "\e042";
$glyphicon-book: "\e043";
$glyphicon-bookmark: "\e044";
$glyphicon-print: "\e045";
$glyphicon-camera: "\e046";
$glyphicon-font: "\e047";
$glyphicon-bold: "\e048";
$glyphicon-italic: "\e049";
$glyphicon-text-height: "\e050";
$glyphicon-text-width: "\e051";
$glyphicon-align-left: "\e052";
$glyphicon-align-center: "\e053";
$glyphicon-align-right: "\e054";
$glyphicon-align-justify: "\e055";
$glyphicon-list: "\e056";
$glyphicon-indent-left: "\e057";
$glyphicon-indent-right: "\e058";
$glyphicon-facetime-video: "\e059";
$glyphicon-picture: "\e060";
$glyphicon-map-marker: "\e062";
$glyphicon-adjust: "\e063";
$glyphicon-tint: "\e064";
$glyphicon-edit: "\e065";
$glyphicon-share: "\e066";
$glyphicon-check: "\e067";
$glyphicon-move: "\e068";
$glyphicon-step-backward: "\e069";
$glyphicon-fast-backward: "\e070";
$glyphicon-backward: "\e071";
$glyphicon-play: "\e072";
$glyphicon-pause: "\e073";
$glyphicon-stop: "\e074";
$glyphicon-forward: "\e075";
$glyphicon-fast-forward: "\e076";
$glyphicon-step-forward: "\e077";
$glyphicon-eject: "\e078";
$glyphicon-chevron-left: "\e079";
$glyphicon-chevron-right: "\e080";
$glyphicon-plus-sign: "\e081";
$glyphicon-minus-sign: "\e082";
$glyphicon-remove-sign: "\e083";
$glyphicon-ok-sign: "\e084";
$glyphicon-question-sign: "\e085";
$glyphicon-info-sign: "\e086";
$glyphicon-screenshot: "\e087";
$glyphicon-remove-circle: "\e088";
$glyphicon-ok-circle: "\e089";
$glyphicon-ban-circle: "\e090";
$glyphicon-arrow-left: "\e091";
$glyphicon-arrow-right: "\e092";
$glyphicon-arrow-up: "\e093";
$glyphicon-arrow-down: "\e094";
$glyphicon-share-alt: "\e095";
$glyphicon-resize-full: "\e096";
$glyphicon-resize-small: "\e097";
$glyphicon-exclamation-sign: "\e101";
$glyphicon-gift: "\e102";
$glyphicon-leaf: "\e103";
$glyphicon-fire: "\e104";
$glyphicon-eye-open: "\e105";
$glyphicon-eye-close: "\e106";
$glyphicon-warning-sign: "\e107";
$glyphicon-plane: "\e108";
$glyphicon-calendar: "\e109";
$glyphicon-random: "\e110";
$glyphicon-comment: "\e111";
$glyphicon-magnet: "\e112";
$glyphicon-chevron-up: "\e113";
$glyphicon-chevron-down: "\e114";
$glyphicon-retweet: "\e115";
$glyphicon-shopping-cart: "\e116";
$glyphicon-folder-close: "\e117";
$glyphicon-folder-open: "\e118";
$glyphicon-resize-vertical: "\e119";
$glyphicon-resize-horizontal: "\e120";
$glyphicon-hdd: "\e121";
$glyphicon-bullhorn: "\e122";
$glyphicon-bell: "\e123";
$glyphicon-certificate: "\e124";
$glyphicon-thumbs-up: "\e125";
$glyphicon-thumbs-down: "\e126";
$glyphicon-hand-right: "\e127";
$glyphicon-hand-left: "\e128";
$glyphicon-hand-up: "\e129";
$glyphicon-hand-down: "\e130";
$glyphicon-circle-arrow-right: "\e131";
$glyphicon-circle-arrow-left: "\e132";
$glyphicon-circle-arrow-up: "\e133";
$glyphicon-circle-arrow-down: "\e134";
$glyphicon-globe: "\e135";
$glyphicon-wrench: "\e136";
$glyphicon-tasks: "\e137";
$glyphicon-filter: "\e138";
$glyphicon-briefcase: "\e139";
$glyphicon-fullscreen: "\e140";
$glyphicon-dashboard: "\e141";
$glyphicon-paperclip: "\e142";
$glyphicon-heart-empty: "\e143";
$glyphicon-link: "\e144";
$glyphicon-phone: "\e145";
$glyphicon-pushpin: "\e146";
$glyphicon-usd: "\e148";
$glyphicon-gbp: "\e149";
$glyphicon-sort: "\e150";
$glyphicon-sort-by-alphabet: "\e151";
$glyphicon-sort-by-alphabet-alt: "\e152";
$glyphicon-sort-by-order: "\e153";
$glyphicon-sort-by-order-alt: "\e154";
$glyphicon-sort-by-attributes: "\e155";
$glyphicon-sort-by-attributes-alt: "\e156";
$glyphicon-unchecked: "\e157";
$glyphicon-expand: "\e158";
$glyphicon-collapse-down: "\e159";
$glyphicon-collapse-up: "\e160";
$glyphicon-log-in: "\e161";
$glyphicon-flash: "\e162";
$glyphicon-log-out: "\e163";
$glyphicon-new-window: "\e164";
$glyphicon-record: "\e165";
$glyphicon-save: "\e166";
$glyphicon-open: "\e167";
$glyphicon-saved: "\e168";
$glyphicon-import: "\e169";
$glyphicon-export: "\e170";
$glyphicon-send: "\e171";
$glyphicon-floppy-disk: "\e172";
$glyphicon-floppy-saved: "\e173";
$glyphicon-floppy-remove: "\e174";
$glyphicon-floppy-save: "\e175";
$glyphicon-floppy-open: "\e176";
$glyphicon-credit-card: "\e177";
$glyphicon-transfer: "\e178";
$glyphicon-cutlery: "\e179";
$glyphicon-header: "\e180";
$glyphicon-compressed: "\e181";
$glyphicon-earphone: "\e182";
$glyphicon-phone-alt: "\e183";
$glyphicon-tower: "\e184";
$glyphicon-stats: "\e185";
$glyphicon-sd-video: "\e186";
$glyphicon-hd-video: "\e187";
$glyphicon-subtitles: "\e188";
$glyphicon-sound-stereo: "\e189";
$glyphicon-sound-dolby: "\e190";
$glyphicon-sound-5-1: "\e191";
$glyphicon-sound-6-1: "\e192";
$glyphicon-sound-7-1: "\e193";
$glyphicon-copyright-mark: "\e194";
$glyphicon-registration-mark: "\e195";
$glyphicon-cloud-download: "\e197";
$glyphicon-cloud-upload: "\e198";
$glyphicon-tree-conifer: "\e199";
$glyphicon-tree-deciduous: "\e200";
$glyphicon-cd: "\e201";
$glyphicon-save-file: "\e202";
$glyphicon-open-file: "\e203";
$glyphicon-level-up: "\e204";
$glyphicon-copy: "\e205";
$glyphicon-paste: "\e206";
$glyphicon-alert: "\e209";
$glyphicon-equalizer: "\e210";
$glyphicon-king: "\e211";
$glyphicon-queen: "\e212";
$glyphicon-pawn: "\e213";
$glyphicon-bishop: "\e214";
$glyphicon-knight: "\e215";
$glyphicon-baby-formula: "\e216";
$glyphicon-tent: "\26fa";
$glyphicon-blackboard: "\e218";
$glyphicon-bed: "\e219";
$glyphicon-apple: "\f8ff";
$glyphicon-erase: "\e221";
$glyphicon-hourglass: "\231b";
$glyphicon-lamp: "\e223";
$glyphicon-duplicate: "\e224";
$glyphicon-piggy-bank: "\e225";
$glyphicon-scissors: "\e226";
$glyphicon-bitcoin: "\e227";
$glyphicon-btc: "\e227";
$glyphicon-xbt: "\e227";
$glyphicon-yen: "\00a5";
$glyphicon-jpy: "\00a5";
$glyphicon-ruble: "\20bd";
$glyphicon-rub: "\20bd";
$glyphicon-scale: "\e230";
$glyphicon-ice-lolly: "\e231";
$glyphicon-ice-lolly-tasted: "\e232";
$glyphicon-education: "\e233";
$glyphicon-option-horizontal: "\e234";
$glyphicon-option-vertical: "\e235";
$glyphicon-menu-hamburger: "\e236";
$glyphicon-modal-window: "\e237";
$glyphicon-oil: "\e238";
$glyphicon-grain: "\e239";
$glyphicon-sunglasses: "\e240";
$glyphicon-text-size: "\e241";
$glyphicon-text-color: "\e242";
$glyphicon-text-background: "\e243";
$glyphicon-object-align-top: "\e244";
$glyphicon-object-align-bottom: "\e245";
$glyphicon-object-align-horizontal: "\e246";
$glyphicon-object-align-left: "\e247";
$glyphicon-object-align-vertical: "\e248";
$glyphicon-object-align-right: "\e249";
$glyphicon-triangle-right: "\e250";
$glyphicon-triangle-left: "\e251";
$glyphicon-triangle-bottom: "\e252";
$glyphicon-triangle-top: "\e253";
$glyphicon-console: "\e254";
$glyphicon-superscript: "\e255";
$glyphicon-subscript: "\e256";
$glyphicon-menu-left: "\e257";
$glyphicon-menu-right: "\e258";
$glyphicon-menu-down: "\e259";
$glyphicon-menu-up: "\e260";

@mixin icon($icon) {
  font-family: $icon-font;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: $icon;
}

