$c-rose: #f7a4ae;
$c-rose-bg: #F47383;
$c-carnation: #f0545a;
$c-crail: #B75242;
$c-black: #333;
$-shark: #2E2F34;
$c-heavy-metal: #252624;
$c-gray: #555;
$c-lightgray: #999;
$c-reddish: #f0545a;
$c-white: #fff;
$c-white-transparent: rgba($c-white, 0.8);
$c-tamarillo: #8f0d1c;