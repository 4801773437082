.node.node-teaser.ds-2col {
  .field-title {
    h2 {
      font-size: 20px;
      margin-top: 0.5em;
      margin-bottom: 0;
      font-weight: bold;
    }
    margin-bottom: 20px;
    + .field-article-category,
    + .field-event-dates,
    + .field-post-date {
      font-size: 14px;
      margin-top: -20px;
      margin-bottom: 0.75em;
      color: $c-tamarillo;
    }
  }
}

.page-node {
  .main-content-inner {
    @extend %gray-box;
    padding: $nice-box-padding;
    @include breakpoint($container-sm) {
      padding: $nice-box-padding * 2;
    }
    .page-header {
      color: $c-black;
      border-bottom-color: $c-carnation;
      font-weight: bold;
      margin-bottom: 0.25em;
    }
  }
}


.node.view-mode-full {
  .field-image {
    margin: 20px 0;
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
}