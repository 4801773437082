.node-tour {
  &.view-mode-full {
    .field-event-dates {
      color: $c-crail;
      font-weight: bold;
      font-size: 1.2em;
      margin: 1em 0;
      .date-display-range {
        display: inline;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      &:before {
        @include fa-icon;
        content: $fa-var-calendar;
        width: 1em;
        padding-right: 1ex;
      }
    }
    .field-tour-place {
      &:before {
        @include fa-icon;
        content: $fa-var-map-marker;
        width: 1em;
        padding-right: 1ex;
      }
      color: $c-crail;
      font-weight: bold;
      font-size: 1.2em;
      margin: 1em 0;
      * {
        display: inline;
      }
      div.addressfield-container-inline:after {
        content: ", ";
        display: inline;
        height: auto;
        clear: none;
        visibility: visible;
      }
    }
  }
}