$nice-box-padding: 20px;
$img-teaser-padding-xs: 160px;
$img-teaser-padding-sm: 280px;
$img-teaser-padding-md: 320px;
$img-teaser-padding: 400px;
%gray-box {
  background-color: $c-white-transparent;
  border-radius: 20px;
  box-shadow: 0 5px 20px $c-gray;
  padding: $nice-box-padding;
}

%nice-box {
  margin-bottom: 40px;
  @extend %gray-box;
}

%box-title {
  display: inline-block;
  font-size: 18px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;

  &:after {
    content: "";
    display: block;
    width: 100%;
    margin: 5px auto;
    height: 2px;
    background-color: $c-reddish;
  }
}
%stroke {
  background-image: url(../images/white.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
%brush-box {
  @extend %stroke;
  padding: 60px 30px;
  @include breakpoint($screen-xs) {
    padding: 60px;
  }
  > * {
    @extend %gray-box;
  }
  .pane-title {
    @extend %box-title;
  }
  .pane-content {

  }

}

%brush-box-rtl {
  @extend %brush-box;
  background-image: url(../images/white-rtl.png);
}

%img-teaser {
  position: relative;
  .ds-2col {
    position: static;
    > .group-left {
      position: absolute;
      top: 30px;
      width: $img-teaser-padding-xs;
      @include breakpoint($screen-sm) {
        width: $img-teaser-padding-sm;
      }
      @include breakpoint($screen-md) {
        width: $img-teaser-padding-md;
      }
      @include breakpoint($screen-lg) {
        width: $img-teaser-padding;
      }
      img {
        border-radius: 20px;
        overflow: hidden;
      }
    }
    > .group-right {
      width: auto;
    }
  }
}

%img-teaser-left {
  @extend %img-teaser;
  @extend %brush-box;
  padding-left: 0;
  margin-left: 20px;
  > * {
    padding-left: $nice-box-padding;
    padding-top: $img-teaser-padding-xs * 3 / 5;
    @include breakpoint($screen-xs) {
      padding-top: $nice-box-padding;
    }
    @include breakpoint($screen-sm) {
      padding-left: $img-teaser-padding-sm + $nice-box-padding;
    }
    @include breakpoint($screen-md) {
      padding-left: $img-teaser-padding-md + $nice-box-padding;
    }
    @include breakpoint($screen-lg) {
      padding-left: $img-teaser-padding + $nice-box-padding;
      min-height: 170px;
    }
    @include breakpoint($screen-xs-min $screen-xs-max) {
      .pane-title,
      .field-title,
      .field-post-date,
      .field-article-category {
        padding-left: $img-teaser-padding-xs - 20px;
      }
    }
  }
  .ds-2col {
    > .group-left {
      left: -20px;
    }
  }
}

%img-teaser-right {
  @extend %img-teaser;
  @extend %brush-box-rtl;
  padding-right: 0;
  margin-right: 20px;
  > * {
    padding-right: $nice-box-padding;
    padding-top: $img-teaser-padding-xs * 3 / 5;
    @include breakpoint($screen-xs) {
      padding-top: $nice-box-padding;
    }
    @include breakpoint($screen-sm) {
      padding-right: $img-teaser-padding-sm + $nice-box-padding;
    }
    @include breakpoint($screen-md) {
      padding-right: $img-teaser-padding-md + $nice-box-padding;
    }
    @include breakpoint($screen-lg) {
      padding-right: $img-teaser-padding + $nice-box-padding;
      min-height: 170px;
    }
    @include breakpoint($screen-xs-min $screen-xs-max) {
      .pane-title,
      .field-title,
      .field-post-date,
      .field-article-category {
        padding-right: $img-teaser-padding-xs - 20px;
      }
    }
  }
  .ds-2col {
    > .group-left {
      right: -20px;
    }
  }
}
