.form-type-bef-checkbox {
  clear: both;
  input[type="radio"], input[type="checkbox"] {
    float: left;
    margin-right: 1ex;
  }
  label {
    font-weight: normal;
    float: left;
    width: calc(100% - 50px);
  }
}