.nav-tabs {
  border-bottom: none;
  > li {
    > a {
      margin: 6px 3px;
      border-radius: 4px;
      padding: 4px 10px;
      color: $c-white;
      &:hover, &:focus {
        background-color: $c-reddish;
        border-color: transparent;
        box-shadow: 0 0 5px $c-white;
      }
    }
    &.active {
      > a {
        &, &:hover, &:focus {
          background-color: $c-crail;
          color: $c-white;
          border-color: transparent;
        }
      }
    }
  }
}