.entity-bean.bean-cite {
  .field-cite-content {
     text-align: center;
    &:before, &:after {
      display: inline;
      content: "\00ab";
    }
    &:after {
      content: "\00bb";
    }
  }
  .field-cite-source {
    margin-top: 1em;
    text-align: right;
    font-style: italic;
  }
}
