.view-lessons {
  .time-default {
    display: inline;
  }
  .views-field-field-lesson-level {
    &:before {
      display: inline;
      @include icon($glyphicon-certificate);
    }
    > .field-content {
      display: inline;
    }
  }
  &.view-display-id-page {
    .view-content {
      > .item-list {
        @extend %nice-box;
        h3 {
          color: $c-carnation;
          margin-top: 0;
        }
        > ul {
          list-style: none;
          padding-left: 0;
          @include breakpoint($screen-sm) {
            display: flex;
            margin: 0 -20px;
          }
          > li {
            padding-bottom: 20px;
            @include breakpoint($screen-sm) {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}