.front {
  .region-content {
    .panel-pane {
      &.pane-views-panes.pane-cites-panel-pane-1 {
        @include make-xs-column(12);
        @include make-sm-column(10);
        @include make-sm-column-offset(1);
        @include make-md-column(8);
        @include make-md-column-offset(2);
        background-image: url(../images/lotus-white-transparent.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 40px;
        .pane-content {
          @extend %nice-box;
          margin-bottom: 0;
        }
      }

      &.pane-lessons {
        @extend %nice-box;
        text-align: center;
        .pane-title {
          @extend %box-title;
        }
        .pane-content {
          .view-lessons {
            .view-content {
              .item-list {
                > h3 {
                  display: none;
                }
                > ul {
                  list-style: none;
                  padding-left: 0;
                  margin: 0 -20px;
                  @include breakpoint($screen-sm) {
                    display: flex;
                    justify-content: center;
                  }
                  li {
                    padding-left: 20px;
                    padding-right: 20px;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
      &.pane-news,
      &.pane-events {
        @extend %img-teaser-left;
      }

      &.pane-articles {
        @extend %img-teaser-right;
      }
    }
    .panel-separator {
      clear: both;
    }
  }
}